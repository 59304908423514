let map = null;
let service = null;
let infowindow = null;
export const initMap = (streetAddress, mapRef) => {
	if (!mapRef || !streetAddress) return false;

	if (!window?.google) return false;
	let google = window.google;
	infowindow = new google.maps.InfoWindow();

	if (!mapRef) return false;
	map = new google.maps.Map(mapRef, {
		zoom: 15,
		disableDefaultUI: true,
		mapId: 'googleMap',
	});

	const request = {
		query: `${streetAddress}, Chicago, IL`,
		fields: ['name', 'geometry'],
	};

	service = new google.maps.places.PlacesService(map);
	service.findPlaceFromQuery(request, (results, status) => {
		if (status === google.maps.places.PlacesServiceStatus.OK && results) {
			for (let i = 0; i < results.length; i++) {
				createMarker(results[i]);
			}

			map.setCenter(results[0].geometry.location);
		}
	});

	return true;
};
async function createMarker(place) {
	if (!place.geometry || !place.geometry.location) return;
	const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
	const marker = new AdvancedMarkerElement({
		map: map,
		position: place.geometry.location,
	});

	google.maps.event.addListener(marker, 'click', () => {
		infowindow.setContent(place.name || '');
		infowindow.open(map);
	});
}
