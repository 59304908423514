<script setup>
	import { initMap } from '~/composables/helpers/initMapEmbed';
	import { calculateDecadeByYear } from '~/composables/helpers/date';

	// useHead({
	// 	script: [
	// 		{
	// 			id: 'mess-google-maps-script',
	// 			src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBb3U9fofIbEWHnNnbaAYKM0Au1FzE9yi8&libraries=places&loading=async',
	// 			defer: true,
	// 		},
	// 	],
	// });

	const props = defineProps({
		building: {
			type: Object,
			required: true,
			default: () => ({}),
		},
	});
	const { building } = toRefs(props);

	const formattedBuilding = computed(() => {
		// const postMeta = useState('postMeta', () => false);
		// return postMeta.value;
		return building.value?.postMeta;
	});

	function formatSingleItem(item, key) {
		if (!item || !key)
			return {
				name: null,
				url: null,
			};

		if (key === 'originalCompletionDate') {
			const decade = calculateDecadeByYear(parseInt(item));
			const decadeParam = `${decade} - ${decade + 9}`;
			return {
				name: formattedBuilding?.value?.visibleCompletionDate || item,
				url: `/online-resources/buildings-of-chicago?${key}=${encodeURIComponent(decadeParam)}`,
			};
		} else {
			return {
				name: item,
				url: `/online-resources/buildings-of-chicago?${key}=${encodeURIComponent(item)}`,
			};
		}
	}

	function buildFormattedList(key) {
		let list = [];

		if (!formattedBuilding?.value?.[key]?.length) return list;

		formattedBuilding?.value?.[key].forEach((item) => {
			if (item !== null && item !== undefined) {
				let singleItem = formatSingleItem(item, key);
				list.push(singleItem);
			}
		});

		return list;
	}
	const architects = computed(() => {
		return buildFormattedList('architect');
	});
	const style = computed(() => {
		return buildFormattedList('style');
	});
	const neighborhood = computed(() => {
		return formatSingleItem(formattedBuilding?.value?.neighborhood, 'neighborhood');
	});
	const useType = computed(() => {
		return buildFormattedList('useType');
	});
	const originalCompletionDate = computed(() => {
		return formatSingleItem(formattedBuilding?.value?.originalCompletionDate, 'originalCompletionDate');
	});

	const hasMap = ref(true);
	const googleMap = ref(null);
	onMounted(() => {
		if (formattedBuilding?.value?.streetAddress && googleMap.value) {
			hasMap.value = initMap(formattedBuilding?.value?.streetAddress, googleMap.value);
		}
	});

	watch(formattedBuilding, () => {
		if (formattedBuilding?.value?.streetAddress && googleMap.value) {
			hasMap.value = initMap(formattedBuilding?.value?.streetAddress, googleMap.value);
		}
	});
</script>

<template>
	<section class="block block-building-details" tabindex="-1">
		<div class="container">
			<div class="table-wrapper">
				<table>
					<tbody>
						<tr v-if="formattedBuilding?.officialName">
							<td>
								<p class="h5">Official Name</p>
							</td>
							<td v-html="formattedBuilding?.officialName"></td>
						</tr>
						<tr v-if="formattedBuilding?.streetAddress">
							<td>
								<p class="h5">Address</p>
							</td>
							<td v-html="formattedBuilding?.streetAddress"></td>
						</tr>
						<tr v-if="architects?.length">
							<td>
								<p class="h5">Architect</p>
							</td>
							<td>
								<ul class="list">
									<li v-for="(item, index) in architects" :key="index" class="item">
										<MessLink :href="item.url" class="link">
											{{ item.name }}
										</MessLink>

										<span v-if="index + 1 < architects.length" class="dot">•</span>
									</li>
								</ul>
							</td>
						</tr>
						<tr v-if="style?.length">
							<td>
								<p class="h5">Style</p>
							</td>
							<td>
								<ul class="list">
									<li v-for="(item, index) in style" :key="index" class="item">
										<MessLink :href="item.url" class="link">
											{{ item.name }}
										</MessLink>

										<span v-if="index + 1 < style.length" class="dot">•</span>
									</li>
								</ul>
							</td>
						</tr>
						<tr v-if="neighborhood?.name">
							<td>
								<p class="h5">Neighborhood</p>
							</td>
							<td>
								<MessLink :href="neighborhood.url" class="link">
									{{ neighborhood.name }}
								</MessLink>
							</td>
						</tr>
						<tr v-if="useType?.length">
							<td>
								<p class="h5">Current Use Type</p>
							</td>
							<td>
								<ul class="list">
									<li v-for="(item, index) in useType" :key="index" class="item">
										<MessLink :href="item.url" class="link">
											{{ item.name }}
										</MessLink>

										<span v-if="index + 1 < useType.length" class="dot">•</span>
									</li>
								</ul>
							</td>
						</tr>
						<tr v-if="originalCompletionDate?.name">
							<td>
								<p class="h5">Original Completion Date</p>
							</td>
							<td>
								<MessLink :href="originalCompletionDate.url" class="link">
									{{ originalCompletionDate.name }}
								</MessLink>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div ref="googleMap" id="googleMap" class="map-iframe"></div>
		</div>
	</section>
</template>

<style lang="scss">
	.block-building-details {
		background-color: var(--gray-100);

		.container {
			max-width: 1125px;

			@media (min-width: $mobile) {
				display: flex;
			}
		}

		.table-wrapper {
			padding: 30px;
			background-color: var(--white);

			@media (min-width: $mobile) {
				flex: 1 1 50%;
			}
		}
		table {
			width: 100%;

			tr {
				td {
					width: auto;
					border: 0;
					border-bottom: 1px solid var(--gray-300);
					padding: 20px 30px 20px 0;

					@media (min-width: 1125px) {
						width: 300px;
					}

					&:last-of-type {
						width: auto;
						padding: 20px 0 20px 0;
					}

					p {
						margin-bottom: 0;
					}
				}

				&:last-of-type {
					td {
						border-bottom: 0;
					}
				}
			}

			.mess-link {
				display: inline;
				// border-bottom: 1px solid var(--red);
				color: var(--red);
			}

			.list {
				display: flex;
				flex-wrap: wrap;

				.dot {
					margin: 0 4px;
					color: var(--gray-500);
				}
			}
		}
		.map-iframe {
			aspect-ratio: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			@media (min-width: $mobile) {
				max-width: 440px;
				flex: 0 0 50%;
			}
		}
	}
</style>
